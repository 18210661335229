<template>
	<div class="data-table">
		<div class="data-table-header">
			<div>
				<slot v-if="hasSearchSlot" name="search"></slot>
				<div v-if="searchable && !hasSearchSlot" class='search-box'>
					<input type="text" :placeholder="search_placeholder" :aria-label="search_placeholder" v-model="search_term" />
				</div>
			</div>
			<div>
				<slot v-if="hasCenterSlot" name="middle"></slot>
				<div v-if="utc_note === true && !hasCenterSlot" class="utc-note"><span>NOTE:</span>&nbsp;<i>All time stamps are shown in UTC time.</i></div>
			</div>
			<div>
				<slot v-if="hasPaginationSlot" name="pagination"></slot>
				<pagination
					v-if="!hasPaginationSlot && !useExternalPagination"
					:count="sorted_data.length"
					:page="pagination.page"
					:per_page="per_page"
					v-on:page="goToPage"
					:bottom="false"
				></pagination>
				<pagination
					v-if="!hasPaginationSlot && useExternalPagination"
					:count="pageData.total"
					:page="pageData.current_page"
					:per_page="pageData.per_page"
					v-on:page="goToExternalPage"
					:bottom="false"
				></pagination>
			</div>
		</div>
		
		<div class="table-container">
			<table cellspacing="0" cellpadding="0">
				<thead>
					<tr>
						<th v-for="(header,field) in table_headers"
							:class="headerClass(field,header)"
							:key="field"
							:style="header.style"
							@click="sortBy(field, header)">
								<span v-html="columnHeaderDisplay(header,field)" />
								<i class='fa fa-long-arrow-alt-down' v-if="header.sortable != false"></i>
						</th>
					</tr>
				</thead>
				<tbody>
					<!-- data rows -->
					<tr v-for="(row,index) in list_rows"
						:class="rowClass(row)"
						:key="index">
							<DataTableCell v-for="(header,field) in table_headers"
								:field="field"
								:key="field"
								:header="header"
								:cell_data="row" />
					</tr>
					<!-- no data is available for table -->
					<tr v-if="list_rows.length == 0" >
						<td :colspan="totalHeaders">{{ no_data }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<pagination
			v-if="!useExternalPagination"
			:count="sorted_data.length"
			:page="pagination.page"
			:per_page="per_page"
			v-on:page="goToPage"
			:bottom="true"
		></pagination>
		<pagination
			v-if="useExternalPagination"
			:count="pageData.total"
			:page="pageData.current_page"
			:per_page="pageData.per_page"
			v-on:page="goToExternalPage"
			:bottom="false"
		></pagination>
	</div>
</template>

<script>
// import any required components
import Pagination from '@/components/Table/Pagination.vue';
import DataTableCell from '@/components/Table/DataTableCell.vue';
import _ from 'lodash';
// sortby
import _sortBy from 'lodash/sortBy';

// export component
export default {
// properties that the component will receive from parent
	props: {
		/**
		 * show loader when the user sorts a table.
		 * @type {Object}
		 */
		loader_on_sort : {
			type : Boolean,
			default : false
		},
		/**
		 * data to use for table
		 * @type {Object}
		 */
		table_data:{
			type: Array,
			default: () => []
		},
		/**
		 * table headers and settings for each cell
		 * @type {Object}
		 */
		table_headers:{
			type: Object,
			default: () => {}
		},
		/**
		 * search field placeholder string
		 * @type {Object}
		 */
		search_placeholder: {
			type: String,
			default: "Search Table"
		},
		/**
		 * wether table is searchable or not
		 * will hide search field if set to false
		 * @type {Object}
		 */
		searchable: {
			type: Boolean,
			default: true
		},
		/**
		 * how many items to display per page
		 * @type {Object}
		 */
		per_page:{
			type: Number,
			default: 10
		},
		/**
		 * string to display if no items are on the table.
		 * @type {Object}
		 */
		no_data: {
			type: String,
			default: "No Data Available."
		},
		/**
		 * method to call to set the row css class.
		 * @type {Object}
		 */
		row_class: {
			type: null
		},
		utc_note : {
			type : Boolean,
			default : false
		},
		pageData: {
			type: Object,
			default: null
		}
	},
	// components to use in template
	components:{
		Pagination,
		DataTableCell
	},
	// component data
	data() {
		return {
			filtered_results: [],
			list_rows: [],
			// array to store sorted data
			sorted_data: [],
			// sorting object for table.
			sorting:{
				current: '',
				direction: 'asc'
			},
			pagination:{
				page:1,
				limit:10
			},
			local_data: [],
			search_term: "",
			searchable_columns: []
		};
	},
	// component was mounted
	mounted(){
		this.initialize();
	},
	// any methods needed for this component
	methods: {
		initialize(){
			let counter = 0,
				searchable = [];
			for(let field in this.table_headers){
				// sort by first item in headers
				if(counter == 0) this.sorting.current = field;
				// check if column is searchable
				const is_searchable = (this.table_headers[field].searchable != undefined)? this.table_headers[field].searchable : true;
				if(is_searchable) searchable.push(field);
				// check if column is the default sorting
				if(this.table_headers[field].default_sort != undefined){
					this.sorting = {
						current: field,
						direction: this.table_headers[field].default_sort
					}
				}
				counter ++;
			}
			this.searchable_columns = searchable;

			this.initializeTableData();
		},
		/**
		 * create copies of table data
		 * @return {[type]} [description]
		 */
		initializeTableData(){
			this.local_data = _.cloneDeep(this.table_data);
			this.createRenderedData();
			this.sortTableData();
		},
		headerClass(field,header){
			let sortable = (header.sortable == undefined)? true : header.sortable;
			let string = "";
			if(sortable) string += " sortable";
			if(this.sorting.current == field){
				string += ` sorting ${this.sorting.direction}`;
			}
			return string;
		},
		/**
		 * get display value from header
		 * if not provided use field value.
		 * @param  {[type]} header [description]
		 * @param  {[type]} field  [description]
		 * @return {[type]}        [description]
		 */
		columnHeaderDisplay(header,field){
			if(header.display != undefined) return header.display;
			return field.unSlug().ucfirst();
		},
		/**
		* sort table data using settings in this.sorting
		* @return {[type]} [description]
		*/
		sortTableData(){
			//if(this.useExternalPagination){return;} //it comes sorted
			if(this.loader_on_sort) this.emitter.emit('loader',true);
			if(!this.useExternalPagination){
				let sorted = _sortBy(this.filtered_results,[this.sorting.current]);
				this.sorted_data = (this.sorting.direction == 'asc')? sorted : sorted.reverse();
			} else {
				this.sorted_data = this.filtered_results;
			}
			this.goToPage(this.pagination.page);
			if(this.loader_on_sort) setTimeout(()=> this.emitter.emit('loader',false),0);
		},
		/**
		* header clicked to sort
		* if same switch direction.
		* @param  {string} header sorting header
		* @return {void}
		*/
		sortBy(header, headerData){
			if(headerData.sortable == false) return;
			let direction = this.sorting.direction;
			if(this.sorting.current == header){
				//flip direction
				direction = this.sorting.direction == 'asc'? 'desc' : 'asc';
			} else {
				//use default sort, if it exists and set the new header
				direction = (headerData.default_sort)? headerData.default_sort : 'asc';
			}
			this.sorting = {
				current: header,
				direction: direction
			};
			if(this.useExternalPagination){
				this.$emit('sort',this.sorting);
			} else {
				this.sortTableData();
			}
		},
		searchTable(){
			// no term
			if(this.search_term.length == 0){
				this.filtered_results = _.cloneDeep(this.local_data);
			}else{
				const lowerSeach = this.search_term.toLowerCase();
				// eslint-disable-next-line
				this.filtered_results = _.filter(this.local_data, (row) => {
					let match = false;
					for(let i in this.searchable_columns){
						let column = this.searchable_columns[i];
						let value = null;
						try {
							// eslint-disable-next-line
							value = eval(`row.${column}`);
						}catch(err){
							value = null;
						}
						if(value == null) continue;
						match = value.toLowerCase().indexOf(lowerSeach) !== -1;
						if(match) break;
					}
					return match;
				});
			}
			this.pagination.page = 1;
			this.sortTableData();
		},
		// event listener when user switches pages
        goToPage(page){
            this.pagination.page = page;
            this.paginate();
        },
		goToExternalPage(page){
			this.$emit('page',page);
		},
        /**
         * splice results list and only show current page results.
         * @return void
         */
        paginate(){
            var index = (this.pagination.page - 1) * this.per_page;
            var copy = _.cloneDeep(this.sorted_data);
            this.list_rows = copy.splice(index,this.per_page);
        },
        /**
         * check if row_class is set
         * if so call it
         * @param  {[type]} row_data [description]
         * @return {[type]}          [description]
         */
        rowClass(row_data){
			if(this.row_class == null) return "";
			return this.row_class(row_data);
        },
        /**
         * create array of all rendered data.
         * @return {[type]} [description]
         */
        createRenderedData(){
			for(let i in this.local_data){
				for(let field in this.table_headers){
					let header = this.table_headers[field];
					if(header.render != undefined && header.searchable !== false){
						this.local_data[i][field] = header.render(this.local_data[i]);
					}
				}
			}
			this.filtered_results = _.cloneDeep(this.local_data);
		},
		setSortDisplay(sort, direction = 'asc'){
			this.sorting.current = sort;
			this.sorting.direction = direction;
		}
	},
	// computed properties
	computed:{
		totalHeaders(){
			let total = 0;
			//eslint-disable-next-line
			for(let i in this.table_headers){
				total++;
			}
			return total;
		},
		hasSearchSlot(){
			return !!this.$slots.search;
		},
		hasCenterSlot(){
			return !!this.$slots.middle;
		},
		hasPaginationSlot(){
			return !!this.$slots.pagination;
		},
		useExternalPagination(){
			return this.pageData !== null;
		}
	},
	// property watchers
	watch:{
		table_data(){
			// create a local copy of the table data.
			this.initializeTableData();
		},
		search_term(){
			this.searchTable();
		}
	}
}
</script>