<template>
	<div class="tsa-box low-padding">
		<div class="tsa-section-title">
			
		</div>
		<div class="clearfix"></div>
		<DataTable
			:loader_on_sort="true"
			:utc_note="true"
			:table_headers="table_headers"
			:table_data="queue"
			:page-data="pageData"
			:per_page="perPage"
			@page="gotoPage"
			@sort="sortBy"
			:searchable="false" >
			<template v-slot:search>
				<div>Escalation Reporting
					<div class="form-row">
						Start: <input type="date" aria-label="Report Start Date" v-model="reportStart" /> End: <input type="date" aria-label="Report End Date" v-model="reportEnd" /> <br />
						<ExportButton :export_route="exportReportRoute" />
					</div>
				</div>
			</template>
			<template v-slot:middle>
				<div class="form-row">
					<select v-model="escalationFilter">
						<option value="all-10">View All Cases (Last 10 Days)</option>
						<option value="open">View Open Cases Only</option>
						<option value="late">View Late Cases</option>
					</select>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import CaseDetailsCell from '@/components/SuperAdmin/Dashboard/CaseDetailsCell.vue';
	import DeleteCase from '@/components/SuperAdmin/Dashboard/DeleteCaseCell.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name : "super-admin-escalation-reporting",
		components: {
			DataTable,
			ExportButton
		},
		props: {
			othersLoaded: {
				type: Boolean,
				default: true
			}
		},
		data(){
			return {
				table_headers: {
					'internal_case_number': {
						display: "Case #",
						sortable: false
					},
					'company.name': {
						display: "From Partner"
					},
					'receiver.name': {
						display: "To Partner"
					},
					'priority_level': {
						display: "Priority",
						render: cell_data => {
							let priority = "";
							switch(cell_data.priority){
								case "low":
									priority = "Priority 3";
								break;
								case "medium":
									priority = "Priority 2";
								break;
								case "high":
									priority = "Priority 1";
								break;
							}
							return priority;
						}
					},
					'created_at': {
						format: "date_time",
						default_sort : "desc"
					},
					'document.name': {
						display: "Document Name",
						route: "/super_admin/companies/{document.company_id}/documents/{document.id}/edit/{document.version.document_type}"
					},
					'status': {
						render: cell_data => {
							return cell_data.status.replace("_"," ").ucfirst();
						}
					},
					'details': {
						component: CaseDetailsCell,
						sortable: false
					},
					'': {
						display: "Delete",
						searchable: false,
						component: DeleteCase,
						sortable: false
					}
				},
				reportStart:null,
				reportEnd:null,
				queue: [],
				currentPage: 1,
				pageData: null,
				perPage: 10,
				sortField: "created_at",
				sortDirection: "desc",
				defaultSortDirection: "desc",
				escalationFilter: "open",
			}
		},
		mounted(){
			// set listener to remove user.
			this.emitter.on('sa_confirm_delete_case',user_id => this.deleteCase(user_id));
			this.emitter.on('token_ready', () => {
				if(!this.loadingUpdate && !this.initialPageLoaded){
					this.fetchQueue();
				}
			});
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('sa_confirm_delete_case');
		},
		methods: {
			fetchQueue(){
				this.emitter.emit('loader',true);
				let query = {};
				if(this.searchTerm){
					query.q = this.searchTerm;
				}
				if(this.sortField) {
					query.sort = this.sortField;
				}
				if(this.sortDirection) {
					query.dir = this.sortDirection;
				}
				if(this.filterType) {
					query.filter = this.filterType;
				}
				query.limit = this.perPage;
				query.page = this.currentPage;
				let qString = new URLSearchParams(query).toString();
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/escalation/queue/${this.escalationFilter}?${qString}`)
					.then((pack) => this.processQueue(pack.data))
					.catch((error) => this.queueFailed(error));
			},
			processQueue(pack){
				if(this.othersLoaded) {
					this.emitter.emit('loader',false);
				}
				if(pack.data){
					this.queue = pack.data;
					this.loadingUpdate = false;
					this.initialPageLoaded = true;
					this.my_cases = pack.data;
					this.pageData = pack;
					this.perPage = pack.per_page;
				}
			},
			queueFailed(error){
				//eslint-disable-next-line
				console.error(error);
			},
			gotoPage(page){
				this.currentPage = page;
				this.fetchQueue();
			},
			sortBy(sorting){
				this.sortField = sorting.current;
				this.sortDirection = sorting.direction;
				this.currentPage = 1;
				this.fetchQueue();
			},
			/**
			 * send request to delete user
			 * @return {[type]} [description]
			 */
			deleteCase(case_id){
				this.emitter.emit('loader',true);
				const url = `${process.env.VUE_APP_API_URL}super_admin/escalation/${case_id}/delete`;
				//eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleDeleteCase(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if error alert error
			 * otherwise success message
			 * @return {[type]} [description]
			 */
			handleDeleteCase(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.emitter.emit('alert',response.message);
					this.$emit("reload");
				}
			}
		},
		computed: {
			exportReportRoute() {
				if(this.reportStart && this.reportEnd){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalationFilter}/${this.reportStart}/${this.reportEnd}`; 
				}
				else if(this.reportStart){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalationFilter}/${this.reportStart}`;
				} else if(this.reportEnd){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalationFilter}/0/${this.reportEnd}`;
				}
				return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalationFilter}`;
			},
			/*escalation_filter: {
				get(){ return this.$store.state.escalation_filter; },
				set (newValue){
					this.currentPage = 1;
					this.fetchQueue();
					this.$store.commit('setEscalationFilter',newValue);
					setTimeout(() => this.fetchQueue(), 0);
				}
			}*/
		},
		watch:{
			escalationFilter(newValue, oldValue){
				if(newValue !== oldValue){
					this.currentPage = 1;
					this.fetchQueue();
				}
				
			}
		}
	}
</script>