<template>
	<div class="tsa-profile-form">
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<!-- user data -->
			<div>
				<div class="form-section">Contact Details Admin</div>
				<!-- first name -->
				<div class="form-row with-label" :class="formRowClass('first_name')">
					<label for="auf_first_name">First Name</label>
					<input id="auf_first_name" type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<!-- last name -->
				<div class="form-row with-label" :class="formRowClass('last_name')">
					<label for="auf_last_name">Last Name</label>
					<input id="auf_last_name" type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<!-- email -->
				<div class="form-row with-label" :class="formRowClass('email')">
					<label for="auf_email">Email</label>
					<input id="auf_email" type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<!-- phone -->
				<div class="form-row with-label" :class="formRowClass('phone')">
					<label for="auf_phone">Phone</label>
					<TelephoneField
						field-id="auf_phone"
						:phone="form_data.phone"
						:defaultCountry="user.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						placeholder="Enter Your Phone Number" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<!-- country -->
				<div class="form-row with-label" :class="formRowClass('country_id')">
					<label for="auf_country">Country</label>
					<select id="auf_country" v-model="form_data.country_id">
						<option value="">Choose Country</option>
						<option
							v-for="(country) in countries"
							:key="country.id"
							:value="country.id">{{ country.name }}</option>
					</select>
					<span class="error-block">{{ errors.country_id }}</span>
				</div>
				<!-- city -->
				<div class="form-row with-label" :class="formRowClass('city')">
					<label for="auf_city">City</label>
					<input id="auf_city" type="text" name="city" placeholder="Enter Your City" v-model="form_data.city" />
					<span class="error-block">{{ errors.city }}</span>
				</div>
				<!-- role -->
				<div class="form-row with-label" :class="formRowClass('role')">
					<label for="auf_role">Role</label>
					<select id="auf_role" v-model="form_data.role">
						<option value="">Choose One</option>
						<option
							v-for="(name,role) in roles"
							:key="role"
							:value="role">{{ name }}</option>
					</select>
					<span class="error-block">{{ errors.role }}</span>
				</div>
			</div>
			<div>
				<div v-if="!isEditMode && company.login_method != 'sso'">
					<div class='form-section'>Password Settings</div>
					<div class="with-label form-row" :class="formRowClass('password')">
						<label for="auf_password">Password</label>
						<input id="auf_password" type="password" name="password" placeholder="Enter User Password" v-model="form_data.password" />
						<span class="error-block">{{ errors.password }}</span>
					</div>
					<div class="with-label form-row">
						<label for="auf_confirm">Confirm</label>
						<input id="auf_confirm" type="password" name="password_confirmation" placeholder="Confirm Password" v-model="form_data.password_confirmation" />
						<span class="error-block">{{ errors.password_confirmation }}</span>
					</div>
					<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
						<PasswordRequirements />
					</div>
				</div>

				<div class="form-section">Community Access</div>
				<p class="page-instructions"><strong>NOTE:</strong> If checkbox is disabled, the company community relation specifies that all users have access to this community.</p>
				<div v-for="relation in communities"
					:key="relation.id"
					class="form-row">
						<input :id="'auf_rel_' + relation.id" type="checkbox"
							:value="relation.id"
							:disabled="allUsersAccess(relation)"
							v-model="form_data.community_access" />
						<label :for="'auf_rel_' + relation.id">{{ relation.community.name}}</label>
				</div>
			</div>
			<div>
				<div class='form-row'>
					<button class='tsa-btn pull-right tsa-plain'
						@click.prevent="saveAndNew">Save &amp; New</button>
					<button class='tsa-btn pull-right' @click.prevent="saveUser">Save</button>
				</div>
			</div>
			<div class=tsa-action-buttons v-if="isEditMode">
				<button class='tsa-btn pull-right tsa-danger' @click.prevent="confirmDeleteUser">Delete</button>
				<button class='tsa-btn pull-right tsa-default' @click.prevent="toggleStatus">{{ toggleStatusButtonText }}</button>
			</div>
			
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import _ from 'lodash';
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	import TelephoneField from '@/components/Form/TelephoneField.vue';

	export default {
		name: 'user-form',
		components:{
			PasswordRequirements,
			TelephoneField
		},
		props: {
			user: {
				type: Object,
				default: () => {}
			},
			communities: {
				type: Array,
				default: () => [	]
			},
			access: {
				type: Array,
				default: () => [	]
			},
			company : {
				type : Object,
				default : () => {}
			}
		},
		data(){
			return {
				v_phone : "",
				form_data: {
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					country_id: "",
					city: "",
					role: "",
					type: "basic",
					password: "",
					password_confirmation: "",
					phone_country_code: "",
					community_access:[],
				},
				errors: {},
				// 'super_admin','caller','business_manager','program_manager'
				roles: {
					caller: "Caller",
					program_manager: "Program Manager",
					business_manager: "Business Manager"
				},
				save_and_new: false
			}
		},
		mounted(){
			if(this.isEditMode) this.cloneUser();
			this.emitter.on('admin_confirm_delete_user', () => this.deleteUser());
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('admin_confirm_delete_user');
		},
		methods:{
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * clone user into form data.
			 * @return {[type]} [description]
			 */
			cloneUser(){
				this.form_data = _.clone(this.user);
			},
			handleDefaultSelectedCommunities(){
				let selected = [];
				this.communities.forEach(relation => {
					if(this.allUsersAccess(relation) || this.userHasAcessToCommunity(relation)){
						selected.push(relation.id);
					}
				});
				this.form_data.community_access = selected;
			},
			saveUser(){
				this.save_and_new = false;
				this.updateUser();
			},
			saveAndNew(){
				this.save_and_new = true;
				this.updateUser();
			},
			/**
			 * send request to update user data.
			 * @return {[type]} [description]
			 */
			updateUser(){
				// clear all errors
				this.errors = {};
				this.emitter.emit('loader',true);
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/users/${this.$route.params.id}`,this.form_data)
					.then(response => this.handleUpdateUser(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},

			/**
			 * handle update user response
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleUpdateUser(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "warning",
						message: response.error
					});
					return;
				}
				this.emitter.emit('alert',response.message);
				if(this.save_and_new){
					// stay on current page so user can create another
					this.resetFormData();
				}
				// let parent component user was updated.
				this.$emit('updated',{
					user: response.user,
					save_and_new: this.save_and_new
				});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * check if all users have access to this community relation.
			 * @param  {object} relation CompanyCommunity record
			 * @return {[type]}          [description]
			 */
			allUsersAccess(relation){
				return (relation.user_access_flag == 'all');
			},
			userHasAcessToCommunity(relation){
				let access = false;
				for(let i in this.access){
					if(this.access[i].community_id == relation.community_id){
						access = true;
						break;
					}
				}
				return access;
			},
			/**
			 * confirm that user wants to delete the user.
			 * @return {[type]} [description]
			 */
			confirmDeleteUser(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.user.first_name} ${this.user.last_name} from the system?`,
					flag: "admin_confirm_delete_user"
				});
			},
			/**
			 * send request to delete user
			 * @return {[type]} [description]
			 */
			deleteUser(){
				this.emitter.emit('loader',true);
				const url = `${process.env.VUE_APP_API_URL}admin/company/users/${this.user.id}/delete`;
				//eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleDeleteUser(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if error alert error
			 * otherwise success message
			 * @return {[type]} [description]
			 */
			handleDeleteUser(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.emitter.emit('alert',response.message);
					this.$router.push({name:'admin.users'});
				}
			},
			/**
			 * send request to toggle status
			 * @return {[type]} [description]
			 */
			toggleStatus(){
				this.emitter.emit('loader',true);
				const data = {
					status : (this.user.status == "active")? "inactive" : "active"
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/users/${this.user.id}/status`,data)
					.then(response => this.handleToggleStatus(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * let user know that status was changed.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleToggleStatus(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.$emit('updated',response.user);
					this.emitter.emit('alert',response.message);
				}
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
                    this.form_data.phone_country_code = data;
                }else{
                    // object
                    this.form_data.phone_country_code = data.iso2;
                }
			},
			onPhoneChanged(number){
				this.form_data.phone = number;
			},
			resetFormData(){
				this.form_data = {
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					country_id: "",
					city: "",
					role: "",
					type: "basic",
					password: "",
					password_confirmation: "",
					phone_country_code: "",
					community_access:[],
				}
			}
		},
		watch:{
			user(){
				this.cloneUser();
			},
			access(){
				this.handleDefaultSelectedCommunities();
			},
			communities(){
				this.handleDefaultSelectedCommunities();
			}
		},
		computed:{
			toggleStatusButtonText(){
				return (this.user.status == "active")? "Disable" : "Enable";
			},
			userStatus(){
				if(this.user.status == undefined) return "";
				return this.user.status.ucfirst();
			},
			isEditMode(){
				return this.user.id != undefined;
			},
			...mapState({
				countries: state => state.countries,
				current_user: state => state.user
			})
		}
	}
</script>