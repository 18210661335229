<template>
	<div class="tsa-admin tsa-admin-users">
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest"
			:request_type="request_type" />

		<div class="tsa-admin-header">
			<span class="page-name">{{ company.name }} Users</span> <span v-if="isLimited">&mdash; To modify <button class="tsa-btn tsa-btn-secondary" @click.prevent="contactTSA('Users')">Contact TSANet</button></span>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<!-- User Access Management -->
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">User Access Management <HelpHint :flag="userFlag" /></div>		
					<div class="tsa-data">
						<div class="tsa-data-label">Login Method:</div>
						<div class="tsa-data-value">{{ loginMethod }}</div>
					</div>
					<div class="tsa-data" v-if="company.login_method == 'email_link'">
						<div class="tsa-data-label">Email Domain Match:</div>
						<div class="tsa-data-value">{{ company.domain }}</div>
					</div>
					<div class="tsa-data">
						<div class="tsa-data-label">Login Page:</div>
						<div class="tsa-data-value"><a :href='loginPage'>{{ loginPage }}</a></div>
					</div>
				</div>
				<UserRegistrationQueue v-if="company.use_group_login == 'requires_registration'" :queue="registration_queue" />
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						<button
							class="tsa-btn tsa-outline pull-right"
							@click.prevent="addNewUser"
							><i class="fas fa-plus"></i> Add New</button>
						Users <ExportButton
								title="Export"
								icon_class="fas fa-file-download"
								:export_route="exportUsersUrl" />
					</div>
					<DataTable 
						search_placeholder="Search Users"
						:table_data="users"
						:table_headers="list_headers"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import DataTable from '@/components/Table/DataTable';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
	import ViewUser from '@/components/Admin/Company/ViewUser.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	import UserRegistrationQueue from '@/components/Admin/Company/UserRegistrationQueue.vue';

	export default {
		name: "admin-users",
		components: {
			DataTable,
			HelpHint,
			CompanyChangeRequest,
			ExportButton,
			UserRegistrationQueue
		},
		data(){
			return {
				show_request_window: false,
				has_initialized: false,
				request_type: "",
				users: [],
				list_headers:{
					name: {
						route: "/admin/users/{id}",
						render: cell_data => {
							if(
								(cell_data.first_name == null && cell_data.last_name == null) ||
								(cell_data.first_name == "" && cell_data.last_name == "")
							) return "N/A";
							return 	`${cell_data.first_name} ${cell_data.last_name}`;
						}
					},
					email: {
						display: "Email"
					},
					role: {
						display: "Role"
					},
					registration_type: {
						display: "Created Through",
					},
					status:{
						display: "Status"
					},
					view: {
						display: "View",
						component: ViewUser
					}
				},
				registration_queue: []
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				this.emitter.on('reload_queue', () => this.loadQueue());
				this.emitter.on('token_ready', () => this.loadViewData());
				if(this.jwt_token.access_token != undefined) this.loadViewData();
			},
			loadViewData(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.all([
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}admin/company/users`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}admin/company/registration/queue`)
				])
				//eslint-disable-next-line
				.then(axios.spread((users_response, queue_response ) => {
					this.emitter.emit('loader',false);
					this.users = users_response.data;
					this.registration_queue = queue_response.data;
				}))
			},
			loadQueue(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/registration/queue`)
				.then(response => this.handleLoadQueue(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadQueue(data){
				this.emitter.emit('loader',false);
				this.registration_queue = data;
			},
			addNewUser(){
				this.$router.push({
					name : "admin.user.manage",
					params : {
						id: 0
					}
				});
			},
			contactTSA(request_type){
				this.request_type = request_type;
				this.show_request_window = true;
			},
			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
		},
		computed:{
			loginMethod(){
				if(this.company.login_method == undefined) return "";
				let method = "";
				switch(this.company.login_method){
					case "email_link":
						method = "Email Login Link";
					break;
					case "requires_registration":
						method = "Requires Registration";
					break;
					case "sso":
						method = "Single Sign On";
					break;
				}
				return method;
			},
			loginPage(){
				if(this.company.slug == undefined) return "";
				return process.env.VUE_APP_COMPANY_URL.replace('{slug}',this.company.slug);
			},
			isLimited(){
				return this.company.membership_type == "limited";
			},
			userFlag(){
				return this.isLimited ? "limited_users_user_access_method" : "admin_user_access_method";
				//return "admin_user_access_method";
			},
			exportUsersUrl(){
				return `${process.env.VUE_APP_API_URL}admin/company/users/export`;
			},
			...mapState({
				company: state => state.company,
				jwt_token : state => state.token
			})
		}

	}
</script>