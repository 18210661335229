<template>
	<div>
		<h1>Inactive Users
			<ExportButton
				title="Export"
				icon_class="fas fa-file-excel"
				:export_route="usersDownloadUrl" />
		</h1>
		<div v-if="loadingPages">Loading users: {{ currentPage }}</div>
		<DataTable
			search_placeholder="Search Users"
			:table_data="users"
			:table_headers="list_headers"
		/>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import DataTable from '@/components/Table/DataTable';
import ViewUser from '@/components/SuperAdmin/Users/ViewUser.vue';
import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
export default {
	name: "ListUsers",
	components:{
		DataTable,
		ExportButton
	},
	data() {
		return {
			has_initialized: false,
			loadingPages: false,
			failedAttempts: 0,
			currentPage: 0,
			callDelay: 15,
			users: [],
			loadedUsers: [],
			list_headers:{
				name: {
					route: "/super_admin/user/{id}",
					render: cell_data => {
						if(
							(cell_data.first_name == null && cell_data.last_name == null) ||
							(cell_data.first_name == "" && cell_data.last_name == "")
						) return "N/A";
						return 	`${cell_data.first_name} ${cell_data.last_name}`;
					}
				},
				email: {
					display: "Email"
				},
				'company.name':{
					display: "Company"
				},
				role: {
					display: "Role"
				},
				"last_login.created_at": {
					display: "Last Login",
				},
				status:{
					display: "Status"
				},
				view: {
					display: "View",
					component: ViewUser
				}
			},
		}
	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize(){
			this.has_initialized = true;
			if(this.jwt_token.access_token != undefined) {
				this.loadViewData();
			} else {
				this.emitter.on('token_ready', () => this.loadViewData());
			}
		},
		loadViewData(){
			this.emitter.emit('loader',true);
			this.loadingPages = true;
			this.getPageOfUsers();
		},
		getPageOfUsers(){
			this.emitter.emit('loader',true);
			this.loadingPages = true;
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}super_admin/users/list/` + this.currentPage).then((response) => this.addPageOfUsers(response), () => this.pageLoadFailure());
		},
		addPageOfUsers(res){
			this.failedAttempts = 0;
			if(res.data && res.data.length > 0){
				for(let i = 0; i < res.data.length;++i){
					this.loadedUsers.push(res.data[i]);
				}
				this.currentPage++;
				if(this.currentPage > 5){
					this.callDelay += 40;
				}
				setTimeout(() => this.getPageOfUsers(), this.callDelay);
			} else {
				this.emitter.emit('loader',false);
				this.users = this.loadedUsers;
				this.loadingPages = false;
			}
		},
		pageLoadFailure(){
			this.failedAttempts++;
			if(this.failedAttempts < 3){
				setTimeout(() => this.getPageOfUsers(), this.callDelay);
			} else {
				this.emitter.emit('loader',false);
				this.users = this.loadedUsers;
				this.loadingPages = false;
			}
		}
	},
	computed:{
		...mapState({
			jwt_token : state => state.token
		}),
		usersDownloadUrl(){
			return `${process.env.VUE_APP_API_URL}super_admin/reports/user_aged_logins`;
		},
	}
}
</script>
<style scoped></style>