<template>
	<span>
		<router-link :to="viewCaseEntry" aria-label="Details"><i class="fas fa-eye"></i></router-link>
	</span>
</template>

<script>
	export default {
		name: "admin-partner-group-delete-user",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
		},
		computed: {
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			viewCaseEntry(){
				return {
					name: "super_admin.cases.case_info",
					params: {
						case_id: this.cell_data.id
					}
				};
			}
		}
	}
</script>