<template>
	<div class="tsa-admin-reports">
		<div class="tsa-admin-header">
			<span class="page-name">Reporting &mdash;</span> View Your Usage <br />
			<label>Start: <input type="date" v-model="reportStart" /></label>
			<label class="uk-margin-small-left">End: <input type="date" v-model="reportEnd" /></label>
      <button class="tsa-btn" @click.prevent="exportUsage">Export Details to Excel</button>
		</div>
		<div class="tsa-box low-padding">
			<div class="tsa-section-title">
				Collaboration Requests <HelpHint flag="reports_page" />
			</div>
			<DataTable
				:utc_note="true"
				:table_data="report_data"
				:table_headers="tableHeaders"
				:searchable="true" />
		</div>
	</div>
</template>

<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DataTable from '@/components/Table/DataTable.vue';

	import ViewCaseDetailsCell from '@/components/Admin/Reports/ViewCaseDetailsCell.vue';

	import {mapState} from 'vuex';
	export default {
		name: "admin-reports",
		components: {
			HelpHint,
			DataTable
		},
		data(){
			return {
				has_initialized: false,
				report_data: [],
        firstLoadComplete: false,
        reportStart: "",
        reportEnd: "",
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
        let today = this.$moment();
        let past = this.$moment().subtract(30, "days");
        this.reportStart = past.format("YYYY-MM-DD");
        this.reportEnd = today.format("YYYY-MM-DD");
				
				this.emitter.on('token_ready', () => this.loadUsageReport());
				if(this.jwt_token.access_token != undefined) this.loadUsageReport();
			},
			/**
			 * api request to load usage report
			 * @return {[type]} [description]
			 */
			loadUsageReport(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/reports/usage?start=${this.reportStart}&end=${this.reportEnd}`)
				.then(response => this.handleLoadUsageReport(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * set data locally
			 * @return {[type]} [description]
			 */
			handleLoadUsageReport(response){
        this.firstLoadComplete = true;
				this.emitter.emit('loader',false);
				this.report_data = response;
			},
			/**
			 * send request to export usage to spreadsheet.
			 * @return {[type]} [description]
			 */
			exportUsage(){
        //eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/reports/usage/export?start=${this.reportStart}&end=${this.reportEnd}`,{ 
					'responseType': 'blob',
					'Accept': 'application/vnd.ms-excel' })
				.then(response => this.handleExportUsage(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * create temp link to download response.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleExportUsage(response){
				const url = window.URL.createObjectURL(response);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.company.slug}.usage_report.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		},
		computed: {
			tableHeaders(){
				return {
					type: {},
					case_number: {
						display: `${this.company.name} Case#`
					},
					partner: {},
					created_at: {
						display: "Request Date",
						format: 'date_time',
						default_sort: 'DESC'
					},
					response_date: {
						display: "Response Date",
						format: 'date_time',
					},
					view:{
						display: "Details",
						component: ViewCaseDetailsCell
					}
				};
			},
			...mapState({
				jwt_token: state => state.token,
				company: state => state.company
			})
		},
    watch:{
      reportStart(){
        if(this.firstLoadComplete){
          this.loadUsageReport();
        }
      },
      reportEnd(){
        if(this.firstLoadComplete){
          this.loadUsageReport();
        }
      }
    }
	}
</script>