<template>
	<span>
		<router-link
		aria-label="Details" :to="{name:'admin.case-info',params:{token:cell_data.token}}"><i class="far fa-eye"></i>
		</router-link>
	</span>
</template>

<script>
	export default {
		name: 'admin-view-case-cell',
		components: {

		},
		props: {
			cell_data: {
				type: Object,
				default: () => {}
			}
		}
	}
</script>